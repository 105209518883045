<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 16 }"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <!-- 第一排 -->
          <a-col :span="6">
            <a-form-model-item prop="order_no" label="订单ID">
              <a-input allowClear v-model="searchForm.order_no" enter-button placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_name" label="收/提货人">
              <a-input
                allowClear
                v-model="searchForm.consignee_recipient_name"
                placeholder="请输入收/提货人"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_phone" label="收/提货手机号">
              <a-input
                allowClear
                enter-button
                v-model="searchForm.consignee_recipient_phone"
                placeholder="请输入手机号"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_time_arr" label="收/提货日期">
              <a-range-picker
                valueFormat="YYYY-MM-DD"
                v-model="searchForm.consignee_recipient_time_arr"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 第二排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="order_status" label="订单状态">
              <a-select placeholder="请选择订单状态" v-model="searchForm.order_status">
                <a-select-option
                  v-for="(item, index) in orderStatusOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="place_order_name" label="下单人姓名">
              <a-input
                v-model="searchForm.place_order_name"
                enter-button
                allowClear
                placeholder="请输入下单人姓名"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="place_order_phone" label="下单人手机号">
              <a-input
                allowClear
                enter-button
                v-model="searchForm.place_order_phone"
                placeholder="请输入下单人手机号"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_add_time_arr" label="下单日期">
              <a-range-picker v-model="searchForm.order_add_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第三排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="consignee_address" label="收货地址">
              <a-input
                allowClear
                enter-button
                v-model="searchForm.consignee_address"
                placeholder="请输入收货地址"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_remarks" label="下单备注">
              <a-input
                allowClear
                v-model="searchForm.order_remarks"
                enter-button
                placeholder="请输入备注"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <!-- 占位 -->
          </a-col>
          <a-col :span="6">
            <div class="flex items-center justify-end mr-1 mt-1">
              <a-button @click="resetForm">重置</a-button>
              <a-button class="ml-2" type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
            </div>
          </a-col>
        </a-row>

      </a-form-model>
    </div>

    <base-table
      ref="orderTableRef"
      id="orderTableID"
      :key="tableKey"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">本店的下单列表</h4>
          <a-button type="primary" @click="hanlderNewOrder" html-type="submit">新建订单</a-button>
        </div>
      </template>

      <template #time="{ record }">
        <span>{{formatTime(record.consignee_recipient_time_start)}}~{{formatTime(record.consignee_recipient_time_end.split(' ')[1])}}</span>
      </template>
      <template #order_status="{ text }">
        <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
        <a-tag class="mr-0"
          :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
        >{{ formatDetailOrderStatus(text) }}</a-tag>
      </template>

      <template #operation="{record}">
        <a-button type="link" @click="handlerDetail(record)">详情</a-button>
        <!-- <a v-if="record.order_status !=-1 && (record.is_deliverying==2 || record.is_express==2)"
          class="ml-2"
          type="link"
          @click="handlerQuery(record)"
        >配送查询</a> -->
      </template>
    </base-table>
    
    <!-- 抽屉展示订单详情 -->
    <a-drawer class="title-in-page"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
      <!-- <template slot="title">
        <div class="drawer-class">
          <div>{{`订单ID：${activeOrderNO} (${formatDetailOrderStatus(activeRow.order_status || 1)})`}}
            <template v-if="activeRow.order_status == 1 && activeRow.order_source == 15">
              <a-button type="link" @click="showPay=true">支付</a-button>
              <pay-tip />
            </template>
          </div>
          <a-button
            type="link"
            v-if="[1,2,3].includes(activeRow.order_status)"
            class="cancelOrder-class"
            @click="handlerCancel(activeOrderNO)"
          >取消订单</a-button>
        </div>
      </template> -->
      <orderDetail :orderNo="activeOrderNO" 
                   v-if="isShowDetail"
                   :num="detailKey"
                   @getDetail="(row)=>{activeRow=row}"
                   @order-canceled="initData()"></orderDetail>
    </a-drawer>

    <!-- 地图 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="activeOrderNO" 
        :hasDelivery="activeOrder_hasDelivery" 
        :hasExpress="activeOrder_hasExpress" />
        
    <!-- 支付弹窗 -->
    <!-- <pay-modal v-if="showPay" :show.sync="showPay"
         :orderId="activeRow.order_no"
         :orderType="activeRow.pick_up_delivery_order.suborder_type"
         :totalAmount="Number(activeRow.order_amount)"
         @paystatus="getPayStatus"></pay-modal> -->

    <!-- 取消弹窗（包含其他支付） -->
    <!-- <cancel-modal v-if="isShowCancel"
         :show.sync="isShowCancel"
         :otherPayAmount="otherPayAmount"
         @ok="handlerCancelOtherPay" /> -->

  </div>
</template>

<script>
import moment from "moment"
import pageData from "./columns"
import {
  getOutletsList,
  getStaffList,
} from "@/api/customer.js"
import {
  getAllOrderList,
  // cancelCakeOrder
} from "@/api/shop.js"
import { formatDetailOrderStatus, orderStatusList } from "@/utils/type"
// import PayTip from "@/components/order/pay-tip.vue"
// import CancelModal from '@/components/order/cancel-modal.vue'
import QueryWindow from "@/components/order/queryWindow"
import orderDetail from "./detail.vue"
// import PayModal from '../order/components/pay-modal.vue'

export default {
  components: {
    orderDetail, QueryWindow
  },
  data() {
    return {
      ...pageData,
      showPay: false,
      activeOrderNO: "",
      activeOrder_hasDelivery: false,
      activeOrder_hasExpress: false,
      expand: false,
      isShowDetail: false,
      tableKey: 0,
      detailKey: 0,

      total: 0,
      tableData: [],
      outletsList: [],
      staffList: [],
      searchForm: {
        order_no: "",
        order_source: 0,
        order_status: 0,
        is_update: [],
        orderer_phone: "",
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        order_remarks: "",
        consignee_recipient_name: "",
        consignee_recipient_phone: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        consignee_address: "",
        actual_sale_ascription_outlets_id: undefined,
        order_type: 0,
        goods_name: "",
        goods_type: 0,
        order_amount_start: "",
        order_amount_end: "",
        pay_flow_no: "",
        pay_mode: 0,
        order_finish_time_arr: [],
        order_finish_time_start: "",
        order_finish_time_end: "",
        acceptance_time_arr: [],
        acceptance_time_start: "",
        acceptance_time_end: "",
        page: 1,
        page_count: 20,
      },
      rowKeys: [],
      activeSkuId: 0,
      activeRow: {},
      isShowQuery: false,
      // isShowCancel: false,
      // otherPayAmount: 0,

      orderStatusOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderStatusList),
    }
  },
   watch: {
    activeRow () {
        // if (this.activeRow.order_status == 1) {
        //   if(this.isShowDetail) this.showPay = true
        // }
    }
  },
  mounted() {
        // 如果传入orderId则默认展开该id的详情
    if (this.$route.query.orderId) {
      this.activeOrderNO = this.$route.query.orderId
      this.isShowDetail = true
    }
    this.initData()
  },
  methods: {
    moment,
    formatDetailOrderStatus,

    // getPayStatus(){
    //   this.initData()
    //   ++this.detailKey
    //   this.isShowDetail = true
    //   this.showPay = false
    // },

    async initData() {
      const params = JSON.parse(JSON.stringify(this.searchForm))
      if (params.order_add_time_arr.length > 0) {
        params.order_add_time_start = params.order_add_time_arr[0]
        params.order_add_time_end = params.order_add_time_arr[1]
      }
      if (params.consignee_recipient_time_arr.length > 0) {
        params.consignee_recipient_time_start =
          params.consignee_recipient_time_arr[0]
        params.consignee_recipient_time_end =
          params.consignee_recipient_time_arr[1]
      }
      if (params.acceptance_time_arr.length > 0) {
        params.acceptance_time_start =
          params.acceptance_time_arr[0] + " 00:00:00"
        params.acceptance_time_end = params.acceptance_time_arr[1] + " 23:59:59"
      }

      if (params.order_finish_time_arr.length > 0) {
        params.order_finish_time_start =
          params.order_finish_time_arr[0] + " 00:00:00"
        params.order_finish_time_end =
          params.order_finish_time_arr[1] + " 23:59:59"
      }

      params.is_update = params.is_update.join(",")
      const { data, code } = await getAllOrderList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    async initOutletsList(value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },
    async getStaffList(value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    resetForm() {
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.$refs.searchRef.resetFields()
      this.initData()
    },

    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    handlerDetail(row) {
      // this.activeRow = row
      this.activeOrderNO = row.order_no
      this.isShowDetail = true
    },

    // 物流
    handlerQuery(e) {
      this.activeOrderNO = e.order_no
      this.activeOrder_hasDelivery = e.is_deliverying==2
      this.activeOrder_hasExpress  = e.is_express==2
      this.isShowQuery = true
    },
    // // 取消订单
    // handlerCancel() {
    //   const _this = this
    //   let order_no = this.activeRow.order_no
    //   let otherPayAmount = this.activeRow.other_pay_amount
    //       otherPayAmount = Number(otherPayAmount) || 0

    //   if(otherPayAmount > 0){
    //     this.otherPayAmount = otherPayAmount
    //     this.isShowCancel = true
    //   }else{
    //     this.$confirm({
    //       title: "温馨提示",
    //       content: "确定要取消订单吗?",
    //       okText: "确认",
    //       cancelText: "取消",
    //       onOk() {
    //         cancelCakeOrder({ order_no: order_no }).then(res => {
    //           if (res.code === 0) {
    //             _this.$message.success("已取消")
    //             _this.initData()
    //             ++_this.detailKey
    //             _this.isShowDetail = true
    //           }
    //         })
    //       },
    //     })
    //   }
    // },

    // handlerCancelOtherPay(e){
    //   const _this = this
    //   let params = { 
    //     order_no: this.activeRow.order_no,
    //     other_pay_amount: e.other_pay_amount
    //   }
    //   cancelCakeOrder(params).then(res => {
    //     if (res.code === 0) {
    //       _this.isShowCancel = false
    //       _this.$message.success("已取消")
    //       _this.initData()
    //       ++_this.detailKey
    //       _this.isShowDetail = true
    //     }
    //   })
    // },
    

    hanlderNewOrder() {
      this.$router.push(`/shop-order-add`)
    },

    handlerExpand() {
      this.expand = !this.expand
      ++this.tableKey
    },

    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
    }
  },
}
</script>

<style lang="less">
.search-bottom-class {
  margin-bottom: 20px;
  margin-left: 50px;
}
.drawer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelOrder-class {
  margin-right: 50px;
}
</style>
