<template>
<div class="scroll-y-con">

  <div class="top-title">
    <div>{{`订单ID: ${orderNo} (${formatDetailOrderStatus(data.order_status)})`}}
      <template v-if="data.order_status == 1 && data.order_source == 15">
        <a-button type="link" @click="showPay=true">选择支付方式</a-button>
        <pay-tip />
      </template>
    </div>
    <a-button
      type="link"
      v-if="[1,2,3].includes(data.order_status)"
      class="mr-4 p-0"
      @click="isShowCancel = true"
    >取消订单</a-button>
  </div>

  <div class="page-content">
    <div>
      <a-row>
        <a-col :span="8">
          <span>{{data.order_source === 10 ? '下单人' : '昵称'}}：</span>
          <span>{{ data.place_order_name }}</span>
        </a-col>
        <a-col :span="8">
          <span>{{data.order_source === 10 ? '下单手机号' : '绑定手机号'}}：</span>
          <span>{{ data.place_order_phone }}</span>
        </a-col>
        <a-col :span="8">
          <span>下单时间: </span>
          <span>{{ data.order_add_time }}</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="20">
          <span>下单备注: </span>
          <span>{{ data.order_remarks }}</span>
        </a-col>
      </a-row>
      <a-row class="mt-3" v-if="data.customer_service_remarks">
        <a-col :span="24">
          <span>客服备注: </span>
          <span>{{ data.customer_service_remarks }}</span>
        </a-col>
      </a-row>
      <a-row class="mt-3" v-if="data.private_remarks">
        <a-col :span="24">
          <span>内部备注: </span>
          <span>{{ data.private_remarks }}</span>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单金额: </span>
          <span>{{ data.order_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>运费金额: </span>
          <span>{{ data.freight_amount }}元</span>
        </a-col>
        <a-col :span="6">
          <span style="width:110px">全场活动优惠：</span>
          <span>{{ data.all_event_offers_amount }}元</span>
        </a-col>
        <a-col :span="8">
          <span>支付状态: </span>
          <span :class="{ red: data.pay_status == 1 }">{{ data.pay_status == 1 ? '未支付' : '已支付' }}</span>
          <span v-if="data.pay_status == 1 && data.pay_type==10">（货到付款-{{getCODPayType(data.pay_subtype)}}）</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单价格: </span>
          <span>{{ data.goods_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>会员折扣: </span>
          <span>{{ data.vip_discount_amount }}元</span>
        </a-col>
        <a-col :span="6">
          <span style="width:110px">优惠券优惠：</span>
          <span>{{ data.coupon_offers_amount }}元</span>
        </a-col>
        <a-col :span="8">
          <span>支付时间: </span>
          <span>{{ data.pay_time }}</span>
        </a-col>
      </a-row>

      <div class="mt-3">
        <base-table :columnsData="pay_column" :customHeight="100" :tableData="payData" rowKey="key">
          <!-- <template #cash_delivery_amount="{record}">
            <span v-if="data.pay_status == 2 && data.pay_type == 10"
              >{{getCODPayType(data.pay_subtype)}} {{record.cash_delivery_amount}}</span>
            <span v-else>{{record.cash_delivery_amount}}</span>
          </template> -->
        </base-table>
      </div>

      <a-divider />

      <a-collapse :default-active-key="['1', '2']">
        <a-collapse-panel
          :show-arrow="false"
          key="1"
          v-if="subOrder && subOrder.suborder_no"
          :header="`【${subOrder.suborder_type === 1 ? '自提' : '配送'}】子订单：${subOrder.suborder_no}(${subOrder.suborder_statusname})`"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && subOrder.suborder_type == 2 && subOrder.suborder_status >= 5"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(subOrder.suborder_type)"
            >配送进度</a>
          </template>

          <a-row class="mt-3">
            <a-col :span="6">
              <span>取货方式: </span>
              <span>{{ subOrder.suborder_type === 1 ? '自提' : '配送' }}</span>
            </a-col>
            <a-col :span="7">
              <span>负责网点: </span>
              <span>{{ subOrder.outlets_name }}</span>
            </a-col>
            <a-col :span="11">
              <span>收货时间: </span>
              <span
                v-if="subOrder.suborder_type === 1"
              >{{ subOrder.pick_up_info.pick_up_goods_time_start }}-{{ subOrder.pick_up_info.pick_up_goods_time_end.split(' ')[1] }}</span>
              <span
                v-else-if="subOrder.suborder_type === 2"
              >{{ subOrder.delivery_info.delivery_time_start }}-{{ subOrder.delivery_info.delivery_time_end.split(' ')[1] }}</span>
            </a-col>
          </a-row>

          <!--如果是自提订单 显示这些-->
          <template v-if="subOrder.suborder_type === 1">
            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">取货人: </span>
                <span>{{ subOrder.pick_up_info.recipient_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ subOrder.pick_up_info.recipient_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>
          </template>
          <!--如果是配送订单 显示这些-->
          <template v-if="subOrder.suborder_type === 2">
            <a-row class="mt-3" v-if="subOrder.delivery_info.contacts_phone">
              <a-col class="flex items-center" :span="6">
                <span class="order-detail-title">联系人: </span>
                <span>{{ subOrder.delivery_info.contacts_name }}</span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="order-detail-title">联系电话: </span>
                <span>{{ subOrder.delivery_info.contacts_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">收货人: </span>
                <span>{{ subOrder.delivery_info.consignee_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ subOrder.delivery_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="11">
                <span>收件地址: </span>
                <span>{{ subOrder.delivery_info.consignee_address }}</span>
              </a-col>
            </a-row>
          </template>

          <div class="flex justify-between mt-4 mb-4">
            <span class="text-sm font-bold text-black">购买商品</span>
          </div>
          <base-table
            class="shop-table-class"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            :columnsData="detail_columns"
            rowKey="sku_id"
            :customHeight="400"
            :tableData="pdOrderGoodsList"
          >
            <template #status="{ text }">
              <a-tag v-if="text == 1" color="green">正常</a-tag>
              <a-tag v-else-if="text == 2" color="red">已拆单</a-tag>
              <a-tag v-else color="gray">已退款</a-tag>
            </template>
          </base-table>

          <div
            class="mt-2 justify-between"
            v-if="subOrder.cake_accessory_list && subOrder.cake_accessory_list.length > 0"
          >
            <div>
              <span class="text-sm font-bold text-black">蛋糕配件</span>
            </div>
            <div v-for="(item,i) in subOrder.cake_accessory_list" :key="i">
              <div class="flex mt-2">
                <div>蛋糕名称: {{ item.goods_name }}</div>
                <div class="ml-10">蜡烛: {{ formatBirthdayCandle(item.birthday_candle) }}</div>
                <div class="ml-10">餐具: {{ item.diners_number }}</div>
                <div class="ml-10">生日牌: {{ item.birthday_card }}</div>
              </div>
            </div>
          </div>
          <template v-if="giftList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="gift_columns"
              rowKey="sku_id"
              :tableData="giftList"
            ></base-table>
          </template>
          <template v-if="conponList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠券</span>
            </div>
            <base-table
              class="shop-table-class mt-2"
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="conpon_columns"
              row-key="id"
              :tableData="conponList"
            >
            </base-table>
          </template>
        </a-collapse-panel>

        <a-collapse-panel
          key="2"
          v-if="isHaveExpress || splitGoodsList.length > 0"
          :header="`【快递】子订单：${expressOrder.suborder_no ? expressOrder.suborder_no : '新拆子订单'}(${expressOrder.suborder_statusname || ''})`"
          :show-arrow="false"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && expressOrder.suborder_status >= 2"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(3)"
            >快递进度</a>
          </template>
          <!--如果是快递订单 显示这些-->
          <!--如果是拆单拆出来的快递单，显示这里-->
          <template>
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="8">
                <span class="order-detail-title">订单金额：</span>
                <span>{{ expressOrder.suborder_amount }}元</span>
              </a-col>
              <a-col class="flex items-center" :span="8">
                <span>取货方式：</span>
                <span>快递</span>
              </a-col>
            </a-row>
            <a-row class="mt-3">
              <a-col :span="8">
                <span class="order-detail-title">收货人：</span>
                <span>{{ expressOrder.express_info.consignee_name }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">手机号：</span>
                <span>{{ expressOrder.express_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">收件地址：</span>
                <span>{{ expressOrder.express_info.consignee_address }}</span>
              </a-col>
            </a-row>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">快递商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="splitGoodsList"
            ></base-table>
          </template>
          <template v-if="isHaveExpress">
            <template v-if="giftList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠品</span>
              </div>
              <base-table
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="gift_columns"
                rowKey="sku_id"
                :tableData="giftList"
              ></base-table>
            </template>
            <template v-if="conponList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠券</span>
              </div>
              <base-table
                class="shop-table-class mt-2"
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="conpon_columns"
                row-key="id"
                :tableData="conponList"
              >
              </base-table>
            </template>
          </template>

        </a-collapse-panel>
      </a-collapse>
    </div>
    <div>
      <a-button class="bottom-btn" type="link" @click="isShowFlow = true">查看流水</a-button>
      <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">查看操作记录</a-button>
    </div>
    <!-- 物流 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="orderNo" 
        :hasDelivery="query_delivery" 
        :hasExpress="query_express" />
    <!-- 弹窗：流水、操作记录 -->
    <flow-modal
      v-if="isShowFlow"
      :show.sync="isShowFlow"
      :orderId="orderNo" />
    <opt-modal
      v-if="isShowOptLog"
      :show.sync="isShowOptLog"
      :orderId="orderNo" />
      
  </div>

  <!-- 支付弹窗 -->
  <pay-modal v-if="showPay" :show.sync="showPay"
       :orderId="data.order_no"
       :orderType="data.pick_up_delivery_order.suborder_type"
       :totalAmount="Number(data.order_amount)"
       @paystatus="getPayStatus"></pay-modal>

  <!-- 取消弹窗（包含其他支付） -->
  <cancel-modal v-if="isShowCancel"
       :show.sync="isShowCancel"
       :payInfo="data"
       @ok="handlerCancelSure" />

</div>
</template>

<script>
import { 
  getAllOrderDetail,
  cancelCakeOrder
} from "@/api/shop.js"
import { subCODPayTypeList } from '@/utils/type'
import pageData from "./columns"
import {
  formatBirthdayCandle,
  formatDetailOrderStatus,
  formatPdSubOrderStatus,
  formatEpSubOrderStatus,
} from "@/utils/type"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import CancelModal from '@/components/order/cancel-modal.vue'
import PayModal from '../order/components/pay-modal.vue'
import PayTip from "@/components/order/pay-tip.vue"

export default {
  components: {
    QueryWindow, FlowModal, OptModal, CancelModal, PayModal, PayTip
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      ...pageData,
      requestID: "",
      isHaveExpress: false,
      splitGoodsList: [],
      expressOrder: [],

      pdOrderGoodsList: [],
      payData: [],
      giftList: [],
      conponList: [],
      data: {},
      subOrder: {},
      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,

      showPay: false,
      isShowCancel: false,
      otherPayAmount: 0,

      query_delivery: false,
      query_express: false,
      subCODPayTypeList: subCODPayTypeList
    }
  },
  watch: {
    num () {
      this.initData()
    }
  },
  created() {
    // this.requestID = this.$route.params.id
    this.initData()
  },
  methods: {
    formatBirthdayCandle,
    formatDetailOrderStatus,

    async initData () {
      const { data, code } = await getAllOrderDetail({
        order_no: this.orderNo,
      })
      if (code === 0) {
        this.data = data
        this.data.order_status_show = formatDetailOrderStatus(data.order_status)

        if (!Array.isArray(data.express_order)) {
          this.isHaveExpress = true
          this.expressOrder = data.express_order
          this.splitGoodsList = data.express_order.goods_list
          this.expressOrder.suborder_statusname = formatEpSubOrderStatus(
            data.express_order.suborder_status
          )
        }
        if (!Array.isArray(data.pick_up_delivery_order)) {
          this.subOrder = data.pick_up_delivery_order
          this.subOrder.suborder_statusname = formatPdSubOrderStatus(
            data.pick_up_delivery_order.suborder_status
          )
        }

        this.$emit('getDetail', this.data)

        this.giftList = data.event_gift_goods_list || []
        this.conponList =
          data.event_gift_coupon_list.map((item, i) => {
            item.id = i
            return item
          }) || []
        this.pdOrderGoodsList = data.pick_up_delivery_order.goods_list
        this.payData = [{
          key: "0",
          wx_pay_amount: data.wx_pay_amount,
          vip_card_pay_amount: data.vip_card_pay_amount,
          stored_value_card_pay_amount: data.stored_value_card_pay_amount,
          alipay_amount: data.alipay_amount,
          cash_delivery_amount: data.cash_delivery_amount,
          other_pay_amount: data.other_pay_amount
        }]
      }
    },
    getCODPayType(subpaytype){
      subpaytype = subpaytype || 0
      let item = this.subCODPayTypeList.find(el=>{
        if(subpaytype == el.id) return el
      })
      return item && item.name || "未知"
    },
    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },

    // 取消订单
    handlerCancelSure(refundData){
      const _this = this
      let params = {
        ...refundData,
        order_no: this.data.order_no
      }
      cancelCakeOrder(params).then(res => {
        if (res.code === 0) {
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.initData()
          _this.$emit('order-canceled')
        }
      })
    },

    getPayStatus(){
      this.initData()
      this.showPay = false
    },

  },
}
</script>

<style lang="less" scoped>
.page-content{
  .red{
    color: #f00;
  }
}
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}
.pre{
  white-space: pre;
  display: inline-block;
  vertical-align: top;
}
</style>
